import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import BirthdayPartiesSlider from '../components/pages/BirthdayPartiesSlider';
import whyhostimg from '../images/whyhostimg.jpg';
import expectimg2 from '../images/expectimg2.jpg';
import LineFull from '../images/linefull.jpg';
import PriceLine from '../images/pricline.png';
import HireDJ from '../images/hiredjimg.jpg';
import HirePhoto from '../images/hirephotoimg.jpg';
import SetupImg from '../images/setupimg.jpg';
import WifiStar from '../images/wifistar.png';
import GreenLogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const BirthDayv2 = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
     
            <SEO
                title={new_seo_title}
                meta={data.wordpressPage.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            {/* 
            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2 className="bluetxt">Looking for additional information.</h2>

                    <div className="bookawrap">
                        <a
                            href={`tel:${post.acf.party_call_us_number}`}
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            CALL US TODAY: {post.acf.party_call_us_number}
                        </a>
                    </div>
                    <img src={LineFull} alt="linefull" />
                </div>
            </section> */}

            <section
                className="page-section smallestwdt respitesec"
                id="toplftcarous"
            >
                <div className="container slider-only">
                    <div className="openplft display_none">
                        <div
                            id="myCarousel3"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <BirthdayPartiesSlider
                                galleryImages={post.acf.bp_gallery}
                            />
                        </div>

                        <div
                            className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        />
                    </div>
                    <div className="openplft">
                        <div className=""
                            dangerouslySetInnerHTML={{
                                __html: `<div class="elfsight-app-0cbbc94d-68c0-48ca-95ed-3307853d7161" data-elfsight-app-lazy></div>`,
                            }}
                        />
                    </div>
                    {/* <div className="openprgt">
                        <h2 className="bluetxt">BOOK YOUR BIRTHDAY PARTY!</h2>

                        <div className="bookawrap">
                            <a
                                href={`tel:${post.acf.party_call_us_number}`}
                                className="wrtsbtn yellowbtn fullbtn"
                            >
                                CALL US TODAY: {post.acf.party_call_us_number}
                            </a>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfmn1H2IL5PmXIhlhDOdQbj7AYf4vOt-QTo-LO4olujsQl1lw/viewform" target="_blank" className="wrtsbtn yellowbtn fullbtn mrg-btn">SUBMIT AN ONLINE REQUEST</a>
                            <p className='disc_txt_btn'>*This is a request for information only.</p>
                        </div>
                    </div> */}
                </div>
            </section>
            <section className="page-section centersec nopaddbottsec" id='nd-boxed'>
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_title,
                        }}
                    />
                    <p className='desc_pricingnew'
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_description,
                        }}
                    />
                    <img className="linemar nomartopimg" src={LineFull} alt="linefull" />
                    <div className="new-mg-bott"
                        dangerouslySetInnerHTML={{
                            __html: `<div class="elfsight-app-20101266-ddc8-4f27-b77e-8e2c87e8599b" data-elfsight-app-lazy></div>`,
                        }}
                    />
                    <div className="packagesdiv bp-newest display_none">
                        {post.acf.bp_pricing_list.map((item, i) => (
                            <div
                                className="pricingbox bg-primary text-white"
                                key={i}
                            >
                                <h2>
                                    <span
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: item.bp_price_title,
                                        }}
                                    />
                                    <br />

                                </h2>
                                <div className="smallerprice"
                                    dangerouslySetInnerHTML={{
                                        __html: item.bp_price_ammount,
                                    }}
                                />
                                <img src={PriceLine} alt="priceline" />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.bp_price_package_content,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section
                className="page-section smallestwdt respitesec notoppaddsec"
                id="RockStar"
            >
                <div className="openprgt new_bp_call">
                    <h2 className="bluetxt">Looking for additional information.</h2>

                    <div className="bookawrap">
                        <a
                            href={`tel:${post.acf.party_call_us_number}`}
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            CALL US TODAY: {post.acf.party_call_us_number}
                        </a>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfmn1H2IL5PmXIhlhDOdQbj7AYf4vOt-QTo-LO4olujsQl1lw/viewform" target="_blank" className="wrtsbtn yellowbtn fullbtn mrg-btn">SUBMIT AN ONLINE REQUEST</a>
                        <p className='disc_txt_btn'>*This is a request for information only.</p>
                    </div>
                </div>
                <img className="linemar" src={LineFull} alt="linefull" />
                <div className="container">
                    <div className='c_heals display_none'>
                        <div className="oplayhealwrap">
                            <h5 class="bluetxt">Rock Star</h5>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_book_online_code_widget,
                                }}
                            />
                        </div>
                        <div className="oplayhealwrap">
                            <h5 class="bluetxt">Rock Star Platinum</h5>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_book_online_code_widget,
                                }}
                            />
                        </div>
                        <div className="oplayhealwrap">
                            <h5 class="bluetxt">Weekday Rock Star</h5>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_book_online_code_widget,
                                }}
                            />
                        </div>
                        <div className="oplayhealwrap">
                            <h5 class="bluetxt">Weekday Rock Star Platinum</h5>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: post.acf.bp_book_online_code_widget,
                                }}
                            />
                        </div>
                    </div>

                    {/*               <section className="page-section centersec nopaddbottsec pad-top-0">
                <div className="container smallestwdt">
                  <h2 className="bluetxt">BIRTHDAY SPRINKLES</h2>
                    <div className="new-add-box">
                        <ul className="startxt starlistspacing">
<li>Dance Class $89</li>
<li>Music Class $89</li>
<li>Gymnastics Class $89</li>
<li>Balloon Drop $129</li>
<li>Exclusive Access $149</li>
<li>Glow in the Dark $149</li>
<li>Face Painting $199</li>
<li>Balloon Artist $249</li>
<li>Party Favors - Per Request</li>
<li>Characters - Per Request</li>
<li>Additional Hour - Per Request</li>
                        </ul>
                    </div>



                </div>
                               <img className="linemar" src={LineFull} alt="linefull" />
            </section> */}
                    <div className="openprgt centertext">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container flexwrap">
                    <div className="whylistlft flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_why_image.localFile.childImageSharp
                                    .fluid.src
                            }
                            alt="why hosting"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_why_reasons,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section
                className="page-section bg-secondary text-white openphours"
                id="pricingsec"
            >
                <div className="container middlewdt flexwrap">
                    <div className="flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_what_to_expect_content,
                            }}
                        />
                    </div>

                    <div className="flexbox todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.bp_what_to_expect_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="whitebordering"
                        />
                    </div>
                </div>
            </section>

            <section className="page-section centersec">
                <div className="container smallestwdt">


                    <h2 className="bluetxt">BIRTHDAY SPRINKLES</h2>
                    <div className="new-add-box">
                        <ul className="startxt starlistspacing">
                            <li>Dance Class $89</li>
                            <li>Music Class $89</li>
                            <li>Gymnastics Class $89</li>
                            <li>Balloon Drop $129</li>
                            <li>Exclusive Access $149</li>
                            <li>Glow in the Dark $149</li>
                            <li>Face Painting $199</li>
                            <li>Balloon Artist $249</li>
                            <li>Party Favors - Per Request</li>
                            <li>Characters - Per Request</li>
                            <li>Additional Hour - Per Request</li>
                        </ul>
                    </div>

                    <img className="linemar" src={LineFull} alt="linefull" />


                    <div className="greenlft">
                        <img src={GreenLogo} alt="greenlogo" />
                    </div>

                    <div className="greenrgt">
                        <h2 className="bluetxt">We Use Only Green Products</h2>
                        <p className="biggertxt lastitem">
                            We believe in keeping our precious ones healthy and
                            safe. Our gym is cleaned daily at the end of the day
                            with only green products.
                        </p>
                    </div>
                </div>
            </section>
            {/* regular packages */}
            <div className="popup-wrap bp_popup_rs_basic">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/birthday-rockstar-basic.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap bp_popup_rs">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/birthday-rockstar.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>

            <div className="popup-wrap bp_popup_rsp">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/birthday-rockstar-platinum.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap bp_popup_rsp_ultimate">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/birthday-rockstar-ultimate.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>

{/* weekday */}
            <div className="popup-wrap bp_popup_wrs_basic">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/weekday-birthday-rockstar-basic.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap bp_popup_wrs">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/weekday-birthday-rockstar.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap bp_popup_wrsp">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/weekday-birthday-rockstar-platinum.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <div className="popup-wrap bp_popup_wrsp_ultimate">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/weekday-birthday-rockstar-ultimate.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
            <Footer />
        </>
    );
};

export default BirthDayv2;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                bp_book_online_code_widget
                bp_content
                bp_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_content
                bp_hire_a_dj_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_title
                bp_hire_photographer_content
                bp_hire_photographer_title
                bp_open_play_unlimited_link
                bp_pricing_description
                bp_pricing_title
                bp_private_playdates_title
                bp_private_playdates_content
                bp_set_open_air_content
                bp_title
                bp_set_open_air_title
                bp_what_to_expect_content
                bp_why_title
                bp_why_reasons
                bp_what_to_expect_title
                bp_hire_photographer_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_invitation_pdf {
                    url {
                        source_url
                    }
                }
                bp_pricing_list {
                    bp_price_ammount
                    bp_price_package_content
                    bp_price_title
                }
                bp_set_open_air_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_what_to_expect_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                party_call_us_number
                bp_why_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
